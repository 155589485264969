<template>
  <div
    id="home"
    @mouseup="resizing ? (resizing = false) : ''"
    @mousemove="updateResizing"
  >
    <Map
      class="map"
      v-show="$full || empty"
      :style="{
        width: mapWidth + 'px',
        left: $full ? sidebarWidth + 'px' : '0',
      }"
    />
    <div
      class="swipe-zone"
      v-show="!$full && !sidebarOpened"
      v-touch:swipe.right="openSidebar"
    ></div>
    <div
      class="router-shadow"
      v-if="!empty"
      :style="{ width: $full ? $store.routerWidth + 'px' : '100%' }"
    ></div>
    <div
      class="container-fluid pa-0 router"
      v-if="!empty"
      :style="{ maxWidth: $full ? $store.routerWidth + 'px' : '100%' }"
      ref="router"
    >
      <router-view />
      <div
        class="resizer"
        v-if="$full"
        :style="{ right: $store.routerWidth + 'px' }"
        @mousedown="resizing = true"
      ></div>
    </div>
    <div
      class="blackout"
      v-if="!$full && sidebarOpened"
      @click="openSidebar"
    ></div>
    <div
      class="sidebar"
      :style="{ width: sidebarWidth + 'px' }"
      v-if="$full || sidebarOpened"
    >
      <Sidebar />
    </div>
    <div class="resizerBlock" v-if="resizing"></div>
    <ImagePopup />
  </div>
</template>

<script>
import Map from '@/components/Map'
import Sidebar from '@/components/Sidebar'
import ImagePopup from '@/components/Popups/Image'

export default {
  components: { Map, Sidebar, ImagePopup },
  data() {
    return {
      resizing: false,
      sidebarWidth: 300,
      screenWidth: window.innerWidth,
      needUpdateMap: false,
    }
  },
  events: {
    'open-sidebar'() {
      this.openSidebar()
    },
  },
  created() {
    this.processUTM()
  },
  methods: {
    processUTM() {
      if (this.empty)
        this.$store.sendUTMIfNotAlready({
          utmSource: 'main',
          utmMedium: 'main',
        })
    },
    updateResizing(e) {
      if (!this.resizing) return
      this.$store.routerWidth = Math.max(
        400,
        Math.min(window.innerWidth - e.clientX, 600),
      )
    },
    openSidebar() {
      this.$store.sidebarOpened = !this.$store.sidebarOpened
    },
    _resize() {
      this.screenWidth = window.innerWidth
    },
  },
  computed: {
    empty() {
      return this.$route.path == '/'
    },
    routerWidth() {
      return this.$store.routerWidth
    },
    mapWidth() {
      return this.empty
        ? this.$full
          ? this.screenWidth - this.sidebarWidth
          : this.screenWidth
        : this.screenWidth - this.sidebarWidth - this.routerWidth
    },
    sidebarOpened() {
      return this.$store.sidebarOpened
    },
  },
  watch: {
    mapWidth() {
      if (this.resizing) this.needUpdateMap = true
    },
    empty: {
      immediate: true,
      async handler(empty) {
        this.$store.hotelYa = null
        const iframeRoot = this.$store.iframeRoot
        if (this.$store.isIframe && empty && iframeRoot) {
          this.$events.emit('post-iframe', 'close')
        }
      },
    },
  },
}
</script>

<style lang="scss">
html {
  overflow-y: scroll;
  overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}
#home {
  > .map {
    position: fixed;
    height: 100%;
    padding: 0;
  }
  > .router-shadow {
    position: fixed;
    background-color: white;
    padding: 0;
    top: 0;
    right: 0;
    bottom: 0;
    box-shadow: 0 1px 11px 1px rgba(0, 0, 0, 0.08);
  }
  > .router {
    position: absolute;
    background-color: white;
    padding: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }
  > .sidebar {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 1;
    background-color: white;
    box-shadow: 0 1px 11px 1px rgba(0, 0, 0, 0.08);
    transition: 0.3s all;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  > .resizer {
    position: fixed;
    top: 0;
    bottom: 0;
    width: 6px;
    cursor: e-resize;
  }
  > .resizerBlock {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: e-resize;
    -webkit-user-drag: none;
    -webkit-user-select: none;
  }
  > .blackout {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.25);
  }
  > .swipe-zone {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 30px;
  }
}
</style>
